@font-face {
font-family: '__neueBit_e6c1d9';
src: url(/_next/static/media/d7090dc0c086c098-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__neueBit_Fallback_e6c1d9';src: local("Arial");ascent-override: 76.50%;descent-override: 20.40%;line-gap-override: 5.09%;size-adjust: 70.02%
}.__className_e6c1d9 {font-family: '__neueBit_e6c1d9', '__neueBit_Fallback_e6c1d9';font-weight: 700;font-style: normal
}.__variable_e6c1d9 {--font-neuebit: '__neueBit_e6c1d9', '__neueBit_Fallback_e6c1d9'
}

